
// ================================================================================= IE11
// https://www.npmjs.com/package/url-search-params-polyfill
// npm install url-search-params-polyfill --save
import 'url-search-params-polyfill';

// https://www.npmjs.com/package/formdata-polyfill
// npm install formdata-polyfill
import 'formdata-polyfill'; // https://github.com/jimmywarting/FormData/issues/55

// npm install --save @types/gtag.js


// [x: string]: () => void;

/* interface Window extends EventTarget, WindowTimers, WindowSessionStorage, WindowLocalStorage, WindowConsole, GlobalEventHandlers, IDBEnvironment, WindowBase64, GlobalFetch, WindowOrWorkerGlobalScope, WindowEventHandlers {
    Coiso: () => void;
}

;( function( window ) {

    function Coiso() {
        console.log('PROTOOOO');
    }

    Coiso.prototype.options = {
    }

    window.Coiso = Coiso;
})( window );

const dlg = new Coiso(); */



// https://www.youtube.com/watch?v=4jb4AYEyhRc

/* function Coiso(cena: String): void {
    this.cena = cena;
    this.botao = "Xuxa";
}

Coiso.prototype.proti = function() {
    this.botao = "Proto";
}

const chi = new Coiso('Pupas');

console.log(chi); */

function validateEmailRegExp($emailAdd: string) {
	const emailRegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailRegExp.test($emailAdd);
}


// let ja_foi_validado: Boolean = false;

let formulario: HTMLFormElement;
// let agree_check_box: HTMLInputElement;
// let agree_check_box_label: HTMLInputElement;
// let notification: HTMLElement;
// let notification_wrapper: HTMLElement;
// let check_icon_msg_wrapper: HTMLElement;
// let cross_icon_msg_wrapper: HTMLElement;
let enviar_bot: HTMLButtonElement;
// let required: NodeList;
// let total_required: Number;
// let total_form_control: Number;

const num_min_chars_q_deve_ter: number = Number(2);
// let tudo_bem: number = 0;

//const $gaFormEventPrefix: String = 'Form.:';

document.addEventListener('DOMContentLoaded', function() {
    const dados_inline: HTMLElement = <HTMLElement>document.getElementById('dados-inline');

    ;( function( window: any ) {

        // let incr = Number(0);
        function ListaForms(this: any, formulario_section: HTMLElement, form: HTMLFormElement, bot: HTMLButtonElement) {
            this.formulario_section = formulario_section;
            this.form = form;
            // this.radio_buttons = <NodeList>this.form.querySelectorAll('.form-check-input[type="radio"]');
            // this.radio_label = <NodeList>this.form.querySelectorAll('.radio-label');
            this.agree_check_box = <HTMLInputElement>this.form.querySelector('.form-check-input[type="checkbox"]');
            this.agree_check_box_label = <HTMLInputElement>this.form.querySelector('.agree-check-box-label');
            // this.agree_check_box_label = <HTMLInputElement>this.form.querySelector('.form-check-label');
            this.notification = <HTMLElement>formulario_section.querySelector('.notification');
            this.notification_wrapper = <HTMLElement>this.notification.querySelector('.notification__wrapper');
            this.check_icon_msg_wrapper = <HTMLElement>this.notification_wrapper.querySelector('.notification__check');
            this.cross_icon_msg_wrapper = <HTMLElement>this.notification_wrapper.querySelector('.notification__cross');
            this.required = <NodeList>this.form.querySelectorAll('.required');
            this.total_required = <Number>this.required.length;
            // this.form_control = <NodeList>this.form.querySelectorAll('.form-control'); // inputs
            // this.total_form_control = <Number>this.form_control.length;
            this.bot = bot;
            this.ja_foi_validado = Boolean(false);
            this.tudo_bem = Number(0);

            //console.log(Array(JSON.parse(String(dados_inline.dataset.mailTitulo))));
            // this.mail_prefixs = JSON.parse(String(dados_inline.dataset.mailPrefix))[incr];
            // this.mail_titulos = JSON.parse(String(dados_inline.dataset.mailTitulo))[incr];
            // this.mail_assuntos = JSON.parse(String(dados_inline.dataset.mailAssunto))[incr];
            // console.log('dados_inline', JSON.parse(String(dados_inline.dataset.mailTitulo))[incr]);
            // incr++;


            this.form.onsubmit = function() {
                return false;
            }
        }

        ListaForms.prototype.clicaProto = function() { // CLICK
            const este = this;
            // console.log('clica');
            // notificacao('jaexiste', este);
            if (validacaoTodos(este) === true) {
                if (este.form.contains(este.agree_check_box) && este.agree_check_box.checked !== true) {
                    return;
                }
                envio(este);
            }
            este.check_icon_msg_wrapper.classList.add('d-none');
            este.cross_icon_msg_wrapper.classList.add('d-none');
        }

        window.ListaForms = ListaForms;
    })( window );

    const formulario_wrapper: NodeList = <NodeList>document.querySelectorAll('.formulario');

    // ================================================================================= IE11
    // https://ultimatecourses.com/blog/ditch-the-array-foreach-call-nodelist-hack
    var forEach = function (array: any, callback: any) {
        for (var i = 0; i < array.length; i++) {
            callback.call(i, array[i]);
        }
    };

    forEach(formulario_wrapper, function (item: any, value: any) {
    // formulario_wrapper.forEach( function(item: HTMLElement) {
        const formulario_section: HTMLElement = <HTMLElement>item;
        formulario = <HTMLFormElement>item.querySelector('form');
        enviar_bot = <HTMLButtonElement>formulario.querySelector('.formulario-button');
        const lista = new ListaForms( formulario_section, formulario, enviar_bot);
        enviar_bot.addEventListener('click', lista.clicaProto.bind(lista) );

        // --------------------------------------------- KEYBOARD INPUT VALIDATION EVENT LISTENERS
        formulario.addEventListener('focus', function (focus_event: FocusEvent) {
            const focus_event_target: HTMLInputElement = <HTMLInputElement>focus_event.target;
            if (focus_event_target.tagName === 'INPUT' && focus_event_target.type !== "radio" || focus_event_target.tagName === 'TEXTAREA') {
                if (focus_event_target.value === focus_event_target.defaultValue) {
                    focus_event_target.value = '';
                    if (lista.ja_foi_validado === false) { focus_event_target.classList.remove('error'); }
                }
                focus_event_target.placeholder = '';
            }
        }, true);

        // https://javascript.info/keyboard-events
        // https://keycode.info/
        function telNumericInput(key_event: KeyboardEvent) {
            if ((key_event.key >= '0' && key_event.key <= '9') // nums
            || key_event.key == '+'
            || key_event.key == '('
            || key_event.key == ')'
            || key_event.key == '-'
            || key_event.key == 'ArrowLeft'
            || key_event.key == 'ArrowRight'
            || key_event.key == 'Delete'
            || key_event.key == 'Backspace'
            || key_event.key == 'Control' || key_event.key == 'v'
			|| key_event.key == ' '
            || key_event.key == 'Tab') {
            // || key_event.keyCode == 32 // space bar
            // || key_event.keyCode == 9) { // tab
                return true;
            }
            key_event.preventDefault();
        }

        formulario.addEventListener('keydown', function (key_event: KeyboardEvent) {
            const key_event_target: HTMLInputElement = <HTMLInputElement>key_event.target;
            if (key_event_target.classList.contains('numeric')) {
                telNumericInput(key_event);
            }
        });

        formulario.addEventListener('keyup', function (key_event: KeyboardEvent) {
            const key_event_target: HTMLInputElement = <HTMLInputElement>key_event.target;
            if (key_event_target.tagName === 'INPUT' && key_event_target.type !== "radio" || key_event_target.tagName === 'TEXTAREA') {
                if (key_event.key !== 'Tab' && key_event.key !== 'Shift') { // se não for a tecla TAB + SHIFT
                    validateInputAndTextarea(key_event_target, lista);
                }
            }
        });

        formulario.addEventListener('blur', function (blur_event: Event) {
            const blur_event_target: HTMLInputElement = <HTMLInputElement>blur_event.target;
            if (blur_event_target.tagName === 'INPUT' && blur_event_target.type !== "radio" || blur_event_target.tagName === 'TEXTAREA') {
                if (blur_event_target.value === '') {
                    blur_event_target.value = blur_event_target.defaultValue;
                    blur_event_target.placeholder = blur_event_target.defaultValue;
                    if (lista.ja_foi_validado === false) { blur_event_target.classList.remove('error'); }
                }
            }
        }, true);

        // --------------------------------------------------------------------- NOTIFICATION EVENT LISTENERS
        /* function animationendPrefixed () {
            const el = document.createElement('div'); // dummy element
            // if (el.style.webkitAnimation) return String('webkitAnimationEnd');
            return String('animationend'); // default
        }
        const animationend: string = animationendPrefixed(); */

        function animationendCallBack(event: Event) { // AnimationEvent para limpar qd clicado e faz fade-out
            if (window.getComputedStyle(lista.notification).opacity == '0' && window.getComputedStyle(lista.notification).display == 'flex') {
                lista.notification.classList.remove('d-flex');
                lista.notification.classList.remove('fade-in');
                lista.notification.classList.remove('bg-sucesso');
                lista.notification.classList.remove('bg-danger');
                lista.notification.classList.add('d-none');
            }
        }

        lista.notification.addEventListener('animationend', animationendCallBack, false);

        // ---------------------------------------------

        lista.notification_wrapper.addEventListener('click', function (event: Event) {
            event.preventDefault();
            lista.notification.classList.add('fade-out');
        });

    }); // end forEach

    // --------------------------------------------- INPUT FIELDS VALIDATION FUNCTIONS

    function validateInputAndTextarea(input_and_textarea_el: HTMLInputElement, este: any) {
    // function validateInputAndTextarea(input_and_textarea_el: HTMLInputElement) {
        if (input_and_textarea_el.type === 'email') { // validação do email
            const mailValid = validateEmailRegExp(input_and_textarea_el.value);
            if (mailValid === false) {
                input_and_textarea_el.classList.add('error');
            } else {
                input_and_textarea_el.classList.remove('error');
                este.tudo_bem += 1;
            }
        } else if (input_and_textarea_el.type === 'number') { // validação numérico
            if (input_and_textarea_el.value.length < 1 || input_and_textarea_el.value === '0' || input_and_textarea_el.value === input_and_textarea_el.defaultValue) {
                input_and_textarea_el.classList.add('error');
            } else {
                input_and_textarea_el.classList.remove('error');
                este.tudo_bem += 1;
            }
        } else {  // validação dos restantes input + textarea
            if (input_and_textarea_el.value.length < num_min_chars_q_deve_ter || input_and_textarea_el.value === input_and_textarea_el.defaultValue) {
                input_and_textarea_el.classList.add('error');
            } else {
                input_and_textarea_el.classList.remove('error');
                este.tudo_bem += 1;
            }
        }
        // console.log('validateInputAndTextarea', input_and_textarea_el.type, este.tudo_bem);
    }

    // function validateCheckbox(checkbox_el: HTMLInputElement) {
    function validateCheckbox(este: any) {
        // console.log("VALID CB", este.agree_check_box);
        if (este.form.contains(este.agree_check_box) && este.agree_check_box.checked === false) {
            este.agree_check_box.classList.add('error');
            este.agree_check_box_label.classList.add('error');
        } else if (este.form.contains(este.agree_check_box) && este.agree_check_box.checked === true) {
            este.agree_check_box.classList.remove('error');
            este.agree_check_box_label.classList.remove('error');
            este.tudo_bem += 1;
        }
    }

    function validateRadioButtons(radio_el: HTMLInputElement, este: any) {
        let radiobuttons: NodeList = <NodeList>radio_el.querySelectorAll('.form-check-input[type="radio"]');
        let radiolabel: NodeList = <NodeList>radio_el.querySelectorAll('.radio-label');

        const total_radios = Number(radiobuttons.length);
        let radio_activo = 0;

        // console.log('RADIOS', total_radios);

        for (let $r = 0; $r < total_radios; $r++) {
            // console.log("VALID RADIO", radiobuttons[$r], radiobuttons[$r].checked, $r, total_radios);
            if (radiobuttons[$r].checked === true) {
                radio_activo++;
                // break;
            }
            if ($r === Number(total_radios - 1)) {
                for (let $pr = 0; $pr < total_radios; $pr++) {
                    if (radio_activo === 0) {
                        radiobuttons[$pr].classList.add('error');
                        radiolabel[$pr].classList.add('error');
                    } else {
                        radiobuttons[$pr].classList.remove('error');
                        radiolabel[$pr].classList.remove('error');
                        if ($pr === Number(total_radios - 1)) {
                            este.tudo_bem += 1;
                        }
                        // console.log('este.tudo_bem', este.tudo_bem);
                    }
                }
            }

        }
    }

    function validateSelect(select_el: HTMLSelectElement, este: any) {
        // if (isNaN(select_el.value) === true) { // ERRO
        if (select_el.value === '0') { // ERRO
            select_el.classList.add('error');
        } else {
            select_el.classList.remove('error');
            este.tudo_bem += 1;
        }
    }

    function validacaoTodos(este: any) {
        este.ja_foi_validado = true;
        este.tudo_bem = 0;
        let i: number = 0;

        for (i = 0; i < este.total_required; ++i) {

            // console.log("VALID TUTTI", este.required[i].tagName, este.required[i].name);

            // if (este.form.contains(este.radio_buttons) && este.required[i].type === "radio") { // RADIO
            if (este.required[i].classList.contains('radios__wrapper')) { // RADIO
                // console.log('RADIOS');
                validateRadioButtons(este.required[i], este);
            } else if ( ((<HTMLInputElement>este.required[i]).tagName === 'INPUT' && este.required[i].type !== "radio") || (<HTMLInputElement>este.required[i]).tagName === 'TEXTAREA' ) { // INPUT + TEXTAREA
                // console.log("INPUTS total:", este.total_required, 'type:', este.required[i].type, (este.required[i].tagName === 'INPUT' && este.required[i].type !== "radio"), este.required[i] );
                validateInputAndTextarea(<HTMLInputElement>este.required[i], este);
            } else if (este.form.contains(este.agree_check_box) && (<HTMLInputElement>este.required[i]).tagName === 'LABEL') { // CHECKBOX
                validateCheckbox(<HTMLInputElement>este);
            } else if ((<HTMLSelectElement>este.required[i]).tagName === 'SELECT') { // SELECT
                validateSelect(<HTMLSelectElement>este.required[i], este);
            }
        }

        // console.log('.|.', este.tudo_bem, este.total_required, este.agree_check_box.checked);
        if (este.form.contains(este.agree_check_box)) {
            if (este.tudo_bem === este.total_required && este.agree_check_box.checked === true) {
                return true;
            }
        } else {
            if (este.tudo_bem === este.total_required) {
                return true;
            }
        }

    } //end validacaoTodos func


    // --------------------------------------------- NOTIFICATION

    function notificacao(type: String, este: any) {
        // este.notification.classList.remove('d-none', 'fade-out', 'bg-sucesso', 'bg-danger');
        // classlist caniuse ie11: Does not support multiple parameters for the add() & remove() methods
        este.notification.classList.remove('d-none');
        este.notification.classList.remove('fade-out');
        este.notification.classList.remove('bg-sucesso');
        este.notification.classList.remove('bg-danger');
        este.check_icon_msg_wrapper.classList.remove('d-block');
        este.cross_icon_msg_wrapper.classList.remove('d-block');
        const notification_messages: NodeList = <NodeList>document.querySelectorAll('.notification__message');
        forEach(notification_messages, function (item: any, value: any) {
            // console.log('notification_messages', item);
            item.classList.remove('d-block');
            item.classList.add('d-none');
        });


        if (type === 'enviado') { // ENVIADO
            const check_img_el: HTMLElement = <HTMLElement>este.check_icon_msg_wrapper.querySelector('.notification__icon');
            const check_img_data:string = <string>check_img_el.dataset.src;
            check_img_el.setAttribute('src', check_img_data);
            este.notification.classList.add('d-flex');
            este.notification.classList.add('fade-in');
            este.notification.classList.add('bg-sucesso');
            este.check_icon_msg_wrapper.classList.remove('d-none');
            este.check_icon_msg_wrapper.classList.add('d-block');

            const notification_message_ok: HTMLElement = <HTMLElement>este.check_icon_msg_wrapper.querySelector('.notification__message--ok');
            notification_message_ok.classList.remove('d-none');
            notification_message_ok.classList.add('d-block');

        } else if (type === 'erro' || type === 'jaexiste') {

            const cross_img_el: HTMLElement = <HTMLElement>este.cross_icon_msg_wrapper.querySelector('.notification__icon');
            const cross_img_data:string = <string>cross_img_el.dataset.src;
            cross_img_el.setAttribute('src', cross_img_data);
            este.notification.classList.add('d-flex');
            este.notification.classList.add('fade-in');
            este.notification.classList.add('bg-erro');
            este.cross_icon_msg_wrapper.classList.remove('d-none');
            este.cross_icon_msg_wrapper.classList.add('d-block');
            // console.log('ERRO + JAEXISTE');

            if (type === 'erro') { // ERRO ENVIO

                const notification_message_erro: HTMLElement = <HTMLElement>este.cross_icon_msg_wrapper.querySelector('.notification__message--erro');
                // notification_message_erro.classList.remove('d-none');
                // notification_message_erro.classList.add('d-block');
                notification_message_erro.classList.remove('d-none');
                notification_message_erro.classList.add('d-block');
                // console.log('ERRO', notification_message_erro);

            } else if (type === 'jaexiste') { // NEWSLETTER REGISTO EMAIL JA EXISTE

                // const notification_message: HTMLElement = <HTMLElement>este.cross_icon_msg_wrapper.querySelector('.notification__message');
                const notification_message_jaexiste: HTMLElement = <HTMLElement>este.cross_icon_msg_wrapper.querySelector('.notification__message--jaexiste');
                // notification_message_jaexiste.classList.remove('d-none');
                // notification_message_jaexiste.classList.add('d-block');
                notification_message_jaexiste.classList.remove('d-none');
                notification_message_jaexiste.classList.add('d-block');
                // console.log('JAEXISTE', notification_message_jaexiste);
            }
        }

    }

    // --------------------------------------------- SENDING

    function envio(este: any) {
        // https://stackoverflow.com/questions/9713058/send-post-data-using-xmlhttprequest

        const xhr: XMLHttpRequest = new XMLHttpRequest();

        if (este.form.getAttribute('id') == 'formulario__newsletter') { /////////////////////////////////////////// NEWSLETTER
            const $ficheiroPHP: string = String(dados_inline.dataset.url) + '/newsletter/newsletter.php';
        } else { ////////////////////////////////////////////////////////////////////////////////////////////////// CONTACTOS
            const $ficheiroPHP: string = String(dados_inline.dataset.url) + '/email/pombocorreio.php';
        }

        const dados_form: FormData = new FormData(este.form);

        if (este.form.contains(este.agree_check_box)) { dados_form.append('agreement', String(este.agree_check_box_label.innerHTML)); }
        dados_form.append('lg', String(dados_inline.dataset.lg));
        dados_form.append('mailtitulo', String(este.form.dataset.mailTitulo));
        dados_form.append('mailsubject', String(este.form.dataset.mailAssunto));
        // dados_form.append('formid', String(este.form.getAttribute("id")));
        // dados_form.append('purl', String(window.location.href));

        // ================================================================================= IE11
        /* new Request(este.form, {
            method: 'post',
            body: dados_form._blob ? dados_form._blob() : dados_form
        }) */

        // este.bot.innerHTML = String(dados_inline.dataset.botEnviando);
        este.bot.innerHTML = String(este.form.dataset.botEnviando);

        // http://www.masteringreactjs.com/2018/04/11/one-neat-typescript-trick-that-saved-the-day/
        const dados_serializados: URLSearchParams = new URLSearchParams(dados_form);
        // const dados_serializados: URLSearchParams = new URLSearchParams((dados_form).toString());

        xhr.open('POST', $ficheiroPHP, true);

        xhr.onload = function () {
            // console.log('onload - DONE', xhr.readyState, 'status: ', xhr.status, 'responseText: ', xhr.responseText); // readyState will be 4
            if(xhr.readyState === 4 && xhr.status === 200 && xhr.responseText === 'true') { // enviado
                document.removeEventListener('blur', function (){});

                // if (typeof gtag === 'function') { gtag('event', String(este.mail_prefixs), { 'event_category' : String(este.mail_prefixs + dados_inline.dataset.titulo), 'event_label' : String(este.mail_prefixs + dados_inline.dataset.titulo) }); }
                if (typeof gtag === 'function') { gtag('event', String(este.form.dataset.mailPrefix), { 'event_category' : String(este.form.dataset.mailPrefix), 'event_label' : String(este.form.dataset.mailPrefix) + ':' + String(dados_inline.dataset.lg) }); }

                notificacao('enviado', este);
                este.form.reset();
            } else if (xhr.responseText === 'jaexiste') { // NEWSLETTER REGISTO EMAIL JA EXISTE
                notificacao('jaexiste', este);
            } else {
                notificacao('erro', este);
            }
            este.ja_foi_validado = false;
            // este.bot.innerHTML = String(dados_inline.dataset.botEnviar);
            este.bot.innerHTML = String(este.form.dataset.botEnviar);
        };

        xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        // xhr.send(dados_serializados + '&agreement='+ este.agree_check_box_label.innerHTML + '&lg=' + dados_inline.dataset.lg + '&mailtitulo=' + este.mail_titulos + '&mailsubject=' + este.mail_assuntos + '&formid=' + este.form.getAttribute("id") + '&purl=' + window.location.href);

        xhr.send(dados_serializados.toString());

        /* if (este.form.contains(este.agree_check_box)) {
            xhr.send(dados_serializados + '&agreement='+ este.agree_check_box_label.innerHTML + '&lg=' + dados_inline.dataset.lg + '&mailtitulo=' + este.form.dataset.mailTitulo + '&mailsubject=' + este.form.dataset.mailAssunto + '&formid=' + este.form.getAttribute("id") + '&purl=' + window.location.href);
        } else {
            xhr.send(dados_serializados + '&lg=' + dados_inline.dataset.lg + '&mailtitulo=' + este.form.dataset.mailTitulo + '&mailsubject=' + este.form.dataset.mailAssunto + '&formid=' + este.form.getAttribute("id") + '&purl=' + window.location.href);
        } */
    }

}); // DOMContentLoaded
